var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container"},[_c('h3',{staticClass:"page-title"},[_vm._v("Senador(a) Federal")]),_c('div',{staticClass:"box-senador form-group"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-md-4 col-lg-2 text-center"},[_c('img',{staticClass:"img-thumbnail img-responsive img-senador",attrs:{"src":'//static.ops.net.br/senador/' +
              _vm.senador.id_sf_senador +
              '_240x300.jpg',"title":_vm.senador.nome_parlamentar,"alt":_vm.senador.nome_parlamentar,"width":"170","height":"210"}})]),_c('div',{staticClass:"col-xs-12 col-md-8 col-lg-10 text-left"},[_c('h3',{staticStyle:{"margin-top":"0"}},[_vm._v(" "+_vm._s(_vm.senador.nome_parlamentar)+" "),_c('small',[_vm._v("("+_vm._s(_vm.senador.sigla_partido)+" / "+_vm._s(_vm.senador.sigla_estado)+")")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Nome civil:")]),_vm._v(" "+_vm._s(_vm.senador.nome_civil)+" ")]),_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Partido:")]),_vm._v(" "+_vm._s(_vm.senador.nome_partido)+" ")]),_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Estado:")]),_vm._v(" "+_vm._s(_vm.senador.nome_estado)+" ")]),_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Condição:")]),_vm._v(" "+_vm._s(_vm.senador.condicao)+" ")]),_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Naturalidade:")]),_vm._v(" "+_vm._s(_vm.senador.naturalidade)+" ")]),_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Nascimento:")]),_vm._v(" "+_vm._s(_vm.senador.nascimento)+" ")])]),_c('div',{staticClass:"col-sm-6"},[_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Gasto Acumulado com Pessoal: ")]),_c('a',{attrs:{"href":'/#/senado/remuneracao?ag=6&sn=' + _vm.senador.id_sf_senador,"title":"Clique para ver os gastos com pessoal em detalhes"}},[_vm._v("R$ "+_vm._s(_vm.senador.valor_total_remuneracao))])]),_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Gasto Acumulado CEAPS: ")]),_c('a',{attrs:{"href":'/#/senador?IdParlamentar=' +
                    _vm.senador.id_sf_senador +
                    '&Periodo=0&Agrupamento=6',"title":"Clique para ver os gastos com cota parlamentar em detalhes"}},[_vm._v("R$ "+_vm._s(_vm.senador.valor_total_ceaps))])]),_c('p',{staticClass:"mb-0"},[_c('a',{attrs:{"href":'http://www25.senado.leg.br/web/senadores/senador/-/perfil/' +
                    _vm.senador.id_sf_senador,"target":"_blank"}},[_vm._v("Ver página oficial no senado")])]),_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Email:")]),_vm._v(" "+_vm._s(_vm.senador.email))])])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-6"},[_c('div',{staticClass:"card mb-3"},[_c('div',{staticClass:"card-header bg-light"},[_vm._v(" Gastos anuais com a cota parlamentar (CEAPS) ")]),_c('div',{staticClass:"card-body chart-senador-presenca"},[_c('highcharts',{ref:"chartSenadorGastosPorAno",attrs:{"options":_vm.chartSenadorGastosPorAnoOptions}})],1)])]),_c('div',{staticClass:"col-xs-12 col-sm-6"},[_c('div',{staticClass:"card mb-3"},[_c('div',{staticClass:"card-header bg-light"},[_vm._v(" Gastos anuais com verba de gabinete (Secretários Parlamentares) ")]),_c('div',{ref:"SenadorGastosComPessoalPorAno",staticClass:"card-body chart-senador-gastos-por-mes vld-parent"},[_c('highcharts',{ref:"chartSenadorGastosComPessoalPorAno",attrs:{"options":_vm.chartSenadorGastosComPessoalPorAnoOptions}})],1)])])]),_c('div',{staticClass:"row form-group"},[_c('div',{staticClass:"col-xs-12 col-sm-6"},[_c('div',{staticClass:"card mb-3"},[_c('div',{staticClass:"card-header bg-light"},[_c('a',{staticClass:"float-right",attrs:{"href":'/#/senador?IdParlamentar=' +
                _vm.senador.id_sf_senador +
                '&Periodo=0&Agrupamento=3'}},[_vm._v("Ver lista completa")]),_vm._v(" Maiores fornecedores ")]),_c('div',{staticClass:"card-body maiores-fornecedores"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-striped table-hover table-sm",staticStyle:{"margin":"0"},attrs:{"aria-label":""}},[_vm._m(0),_c('tbody',_vm._l((_vm.MaioresFornecedores),function(row){return _c('tr',{key:row.id_fornecedor},[_c('td',[_c('a',{attrs:{"href":'/#/fornecedor/' + row.id_fornecedor}},[_vm._v(_vm._s(row.nome_fornecedor))])]),_c('td',{staticClass:"text-right"},[_c('a',{attrs:{"href":'/#/senador?IdParlamentar=' +
                          _vm.senador.id_sf_senador +
                          '&Fornecedor=' +
                          row.id_fornecedor +
                          '&Periodo=0&Agrupamento=6'}},[_vm._v(_vm._s(row.valor_total))])])])}),0)])])])])]),_c('div',{staticClass:"col-xs-12 col-sm-6"},[_c('div',{staticClass:"card mb-3"},[_c('div',{staticClass:"card-header bg-light"},[_c('a',{staticClass:"float-right",attrs:{"href":'/#/senador?IdParlamentar=' +
                _vm.senador.id_sf_senador +
                '&Periodo=0&Agrupamento=6'}},[_vm._v("Ver lista completa")]),_vm._v(" Maiores Notas/Recibos ")]),_c('div',{staticClass:"card-body maiores-notas"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-striped table-hover table-sm",staticStyle:{"margin":"0"},attrs:{"aria-label":""}},[_vm._m(1),_c('tbody',_vm._l((_vm.MaioresNotas),function(row){return _c('tr',{key:row.id_sf_despesa},[_c('td',[_c('a',{attrs:{"href":'/#/fornecedor/' + row.id_fornecedor}},[_vm._v(_vm._s(row.nome_fornecedor))])]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(row.valor))])])}),0)])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"80%"}},[_vm._v("Fornecedor")]),_c('th',{staticClass:"text-right",staticStyle:{"width":"20%"}},[_vm._v("Valor")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"80%"}},[_vm._v("Fornecedor")]),_c('th',{staticClass:"text-right",staticStyle:{"width":"20%"}},[_vm._v("Valor")])])])}]

export { render, staticRenderFns }